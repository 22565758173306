import {Link} from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div className='flex items-center justify-center flex-col pt-32'>
      <h2 className='text-[32px] font-bold'>Yanlış səhifə</h2>
      <div className='w-full pt-16'>
                  <Link
                   to="/"
                    className="flex w-[60%] mx-auto justify-center rounded bg-primary p-3 font-medium text-gray"
                  >
                    Ana səhifəyə qayıt
                  </Link>
                </div>
    </div>
  )
}

export default PageNotFound