// import ChartThree from '../../components/ChartThree';
import Statistics from '../../components/Statistics';
const index = () => {
  return (
    <>
      <Statistics />
      <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
        {/* <ChartThree /> */}
      </div>
    </>
  );
};

export default index;
