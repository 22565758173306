import { lazy } from 'react';


const Settings = lazy(() => import('../pages/Settings'));
const Products = lazy(() => import('../pages/Products/ProductList'));
const ProductCreate = lazy(() => import('../pages/Products/ProductCreate'));
const ProductEdit = lazy(() => import('../pages/Products/ProductEdit'));
const ProductCategories = lazy(
  () => import('../pages/Products/ProductCategories')
);
const Orders = lazy(() => import('../pages/Order/OrderList'));
const OrderCreate = lazy(() => import('../pages/Order/OrderCreate'));
const OrderDetails = lazy(() => import('../pages/Order/OrderDetails'));
const coreRoutes = [
  {
    path: '/settings',
    title: 'Settings',
    component: Settings,
  },
  {
    path: '/products',
    title: 'Products',
    component: Products,
  },
  {
    path: '/product-create',
    title: 'Products Create',
    component: ProductCreate,
  },
  {
    path: '/product-edit/:id',
    title: 'Products Edit',
    component: ProductEdit,
  },
  {
    path: '/products/categories',
    title: 'Product Categories',
    component: ProductCategories,
  },
  {
    path: '/orders',
    title: 'Orders',
    component: Orders,
  },
  {
    path: '/order-create',
    title: 'Order Create',
    component: OrderCreate,
  },
  {
    path: '/order-details/:id',
    title: 'Order Details',
    component: OrderDetails,
  },
];

const routes = [...coreRoutes];
export default routes;
